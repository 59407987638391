<template>
<div>
    <b-row>
        <b-col md="7">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de configuración de información de la app</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name: 'Configuración información app'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <!-- <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Itinerarios registrados </span>
                </CCardHeader> -->
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(verificarAccion)">
                            <b-row>
                                <b-col md="12">
                                    <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                        <b-form-group label="Proyecto:" class="mb-2">
                                            <v-select :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInformacionApp.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInformacionApp.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                            </v-select>
                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider name="tramo" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Tramo:" class="mb-2">
                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tramo" v-model="datosInformacionApp.tramo"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider name="coordenadas de Google" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Coordenadas de Google (ejem: -13.26426, -72.44703):" class="mb-2">
                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese las coordenadas de Google" v-model="datosInformacionApp.coordenadas"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider name="lugar" :rules="{}" v-slot="validationContext">
                                        <b-form-group label="Lugar:" class="mb-2">
                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el lugar" v-model="datosInformacionApp.lugar"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="6">
                                    <validation-provider name="título" :rules="{}" v-slot="validationContext">
                                        <b-form-group label="Título:" class="mb-2">
                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el título" v-model="datosInformacionApp.titulo"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="12">
                                    <validation-provider name="descripción" :rules="{}" v-slot="validationContext">
                                        <b-form-group label="Descripción:" class="mb-2">
                                            <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" v-model="datosInformacionApp.descripcion"></b-form-textarea>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <span class="h6 text-muted">COMUNIDADES</span>
                                    <CButton size="sm" class="float-right" color="dark" @click="agregarComunidad()">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <b-table :items="datosInformacionApp.listaComunidades" :fields="cabeceraComunidades" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                        <template #table-colgroup="cabeceraComunidades">
                                            <col v-for="field in cabeceraComunidades.fields" :key="field.key" :style="{ width: field.key === 'comunidad' ? '40%' : field.key === 'imagen' ? '50%' : '10%'}">
                                        </template>
                                        <template v-slot:cell(comunidad)="row">
                                            <b-form-textarea rows="2" max-rows="6" v-model.lazy="row.item.comunidad" placeholder="Escriba aquí..."></b-form-textarea>
                                        </template>
                                        <template v-slot:cell(imagen)="row">
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="row.item.urlImagen != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlImagen)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="row.item.imagen" :placeholder="row.item.nombreImagen == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreImagen" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarComunidad(param)" v-if="datosInformacionApp.listaComunidades.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <span class="h6 text-muted">ARQUEOLOGÍA</span>
                                    <CButton size="sm" class="float-right" color="dark" @click="agregarArqueologia()">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <b-table :items="datosInformacionApp.listaArqueologias" :fields="cabeceraArqueologias" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                        <template #table-colgroup="cabeceraArqueologias">
                                            <col v-for="field in cabeceraArqueologias.fields" :key="field.key" :style="{ width: field.key === 'arqueologia' ? '40%' : field.key === 'imagen' ? '50%' : '10%'}">
                                        </template>
                                        <template v-slot:cell(arqueologia)="row">
                                            <b-form-textarea rows="2" max-rows="6" v-model.lazy="row.item.arqueologia" placeholder="Escriba aquí..."></b-form-textarea>
                                        </template>
                                        <template v-slot:cell(imagen)="row">
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="row.item.urlImagen != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlImagen)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="row.item.imagen" :placeholder="row.item.nombreImagen == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreImagen" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarArqueologia(param)" v-if="datosInformacionApp.listaArqueologias.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <span class="h6 text-muted">FLORA</span>
                                    <CButton size="sm" class="float-right" color="dark" @click="agregarFlora()">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <b-table :items="datosInformacionApp.listaFloras" :fields="cabeceraFlora" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                        <template #table-colgroup="cabeceraFlora">
                                            <col v-for="field in cabeceraFlora.fields" :key="field.key" :style="{ width: field.key === 'flora' ? '40%' : field.key === 'imagen' ? '50%' : '10%'}">
                                        </template>
                                        <template v-slot:cell(flora)="row">
                                            <b-form-textarea rows="2" max-rows="6" v-model.lazy="row.item.flora" placeholder="Escriba aquí..."></b-form-textarea>
                                        </template>
                                        <template v-slot:cell(imagen)="row">
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="row.item.urlImagen != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlImagen)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="row.item.imagen" :placeholder="row.item.nombreImagen == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreImagen" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarFlora(param)" v-if="datosInformacionApp.listaFloras.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <span class="h6 text-muted">FAUNA</span>
                                    <CButton size="sm" class="float-right" color="dark" @click="agregarFauna()">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <b-table :items="datosInformacionApp.listaFaunas" :fields="cabeceraFauna" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                        <template #table-colgroup="cabeceraFauna">
                                            <col v-for="field in cabeceraFauna.fields" :key="field.key" :style="{ width: field.key === 'fauna' ? '40%' : field.key === 'imagen' ? '50%' : '10%'}">
                                        </template>
                                        <template v-slot:cell(fauna)="row">
                                            <b-form-textarea rows="2" max-rows="6" v-model.lazy="row.item.fauna" placeholder="Escriba aquí..."></b-form-textarea>
                                        </template>
                                        <template v-slot:cell(imagen)="row">
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend v-if="row.item.urlImagen != ''">
                                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(row.item.urlImagen)">
                                                            <i class="fas fa-download fa-xs"></i>
                                                        </b-button>
                                                    </b-input-group-prepend>
                                                    <b-form-file class="text-left" ref="file" v-model="row.item.imagen" :placeholder="row.item.nombreImagen == '' ?  'Elija un archivo o arrástrelo aquí...' : row.item.nombreImagen" accept=".png,.jpg,.jpeg,image/*" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarFauna(param)" v-if="datosInformacionApp.listaFaunas.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <span class="h6 text-muted">HISTORIAS Y REFERENCIAS</span>
                                    <CButton size="sm" class="float-right" color="dark" @click="agregarHistoria()">
                                        <i class="fas fa-plus fa-sm"></i> Agregar
                                    </CButton>
                                </b-col>
                                <b-col md="12" class="mt-2">
                                    <b-table :items="datosInformacionApp.listaHistorias" :fields="cabeceraHistorias" class="table-custom" fixed responsive show-empty mediun outlined hover empty-text="Aún no hay datos en esta sección.">
                                        <template #table-colgroup="cabeceraHistorias">
                                            <col v-for="field in cabeceraHistorias.fields" :key="field.key" :style="{ width: field.key === 'historia' ? '90%'  : '10%'}">
                                        </template>
                                        <template v-slot:cell(historia)="row">
                                            <b-form-textarea rows="2" max-rows="6" v-model.lazy="row.item.historia" placeholder="Escriba aquí..."></b-form-textarea>
                                        </template>
                                        <template #cell(acciones)="param">
                                            <b-button @click="quitarHistoria(param)" v-if="datosInformacionApp.listaHistorias.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>

                                <b-col md="12" class="text-center my-2">
                                    <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                        Guardar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import firebase from 'firebase';
const dbProyecto = firebase.firestore().collection('proyectos');
const dbItinerario = firebase.firestore().collection('itinerarios');
const dbInformacionApp = firebase.firestore().collection('informacionApp');

export default {
    data() {
        return {
            disabled: false,
            cabeceraComunidades: [{
                key: 'comunidad',
                label: 'Comunidad',
                class: 'text-center'
            }, {
                key: 'imagen',
                label: 'Imagen',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            cabeceraArqueologias: [{
                key: 'arqueologia',
                label: 'Arqueología',
                class: 'text-center'
            }, {
                key: 'imagen',
                label: 'Imagen',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            cabeceraFlora: [{
                key: 'flora',
                label: 'Flora',
                class: 'text-center'
            }, {
                key: 'imagen',
                label: 'Imagen',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            cabeceraFauna: [{
                key: 'fauna',
                label: 'Fauna',
                class: 'text-center'
            }, {
                key: 'imagen',
                label: 'Imagen',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            cabeceraHistorias: [{
                key: 'historia',
                label: 'Historia',
                class: 'text-center'
            }, {
                key: 'acciones',
                label: '',
                class: 'text-center'
            }],
            comboProyecto: [],

            datosInformacionApp: {
                idInformacionApp: '',
                idCliente: '',
                idProyecto: '',
                tramo: '',
                coordenadas: '',
                lugar: '',
                titulo: '',
                descripcion: '',
                listaComunidades: [{
                    comunidad: '',
                    imagen: null,
                    urlImagen: '',
                    nombreImagen: '',
                }],
                listaArqueologias: [{
                    arqueologia: '',
                    imagen: null,
                    urlImagen: '',
                    nombreImagen: '',
                }],
                listaFloras: [{
                    flora: '',
                    imagen: null,
                    urlImagen: '',
                    nombreImagen: '',
                }],
                listaFaunas: [{
                    fauna: '',
                    imagen: null,
                    urlImagen: '',
                    nombreImagen: '',
                }],
                listaHistorias: [{
                    historia: '',
                }],
            },
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                // this.datosDocumento.archivoMeta = this.$refs.file.files[0];
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },

        agregarComunidad() {
            let me = this;
            me.datosInformacionApp.listaComunidades.push({
                comunidad: '',
                imagen: null,
                urlImagen: '',
                nombreImagen: '',
            })
        },
        quitarComunidad(param) {
            let me = this;
            me.datosInformacionApp.listaComunidades.splice(param.index, 1);
        },
        agregarArqueologia() {
            let me = this;
            me.datosInformacionApp.listaArqueologias.push({
                arqueologia: '',
                imagen: null,
                urlImagen: '',
                nombreImagen: '',
            })
        },
        quitarArqueologia(param) {
            let me = this;
            me.datosInformacionApp.listaArqueologias.splice(param.index, 1);
        },
        agregarFlora() {
            let me = this;
            me.datosInformacionApp.listaFloras.push({
                flora: '',
                imagen: null,
                urlImagen: '',
                nombreImagen: '',
            })
        },
        quitarFlora(param) {
            let me = this;
            me.datosInformacionApp.listaFloras.splice(param.index, 1);
        },
        agregarFauna() {
            let me = this;
            me.datosInformacionApp.listaFaunas.push({
                fauna: '',
                imagen: null,
                urlImagen: '',
                nombreImagen: '',
            })
        },
        quitarFauna(param) {
            let me = this;
            me.datosInformacionApp.listaFaunas.splice(param.index, 1);
        },
        agregarHistoria() {
            let me = this;
            me.datosInformacionApp.listaHistorias.push({
                historia: '',
            })
        },
        quitarHistoria(param) {
            let me = this;
            me.datosInformacionApp.listaHistorias.splice(param.index, 1);
        },

        listarProyectos() {
            let me = this;
            dbProyecto
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosInformacionApp.idCliente))
                .get()
                .then((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
                    if (me.$route.params.id != null) {
                        me.obtenerInformacionApp();
                    }
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerInformacionApp() {
            let me = this;
            dbInformacionApp.doc(me.$route.params.id).get()
                .then((doc) => {
                    me.datosInformacionApp.idInformacionApp = doc.id;
                    me.datosInformacionApp.idCliente = doc.data().idCliente,
                    me.datosInformacionApp.idProyecto = doc.data().idProyecto,
                    me.datosInformacionApp.tramo = doc.data().tramo,
                    me.datosInformacionApp.coordenadas = doc.data().coordenadas,
                    me.datosInformacionApp.lugar = doc.data().lugar,
                    me.datosInformacionApp.titulo = doc.data().titulo,
                    me.datosInformacionApp.idProyecto = doc.data().idProyecto,
                    me.datosInformacionApp.listaComunidades = doc.data().listaComunidades,
                    me.datosInformacionApp.listaArqueologias = doc.data().listaArqueologias,
                    me.datosInformacionApp.listaFloras = doc.data().listaFloras,
                    me.datosInformacionApp.listaFaunas = doc.data().listaFaunas,
                    me.datosInformacionApp.listaHistorias = doc.data().listaHistorias

                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        verificarAccion() {
            if (this.$route.params.id == null) {
                this.registrarInformacionApp();
            } else if (this.$route.params.id != null) {
                this.actualizarInformacionApp();
            }
        },
        async registrarInformacionApp() {
            let me = this;
            me.disabled = true;

            for (const i in me.datosInformacionApp.listaComunidades) {
                if (me.datosInformacionApp.listaComunidades[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaComunidades[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaComunidades[i].imagen = null;
                            me.datosInformacionApp.listaComunidades[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaComunidades[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaComunidades[i].nombreImagen = '';
                        me.datosInformacionApp.listaComunidades[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaArqueologias) {
                if (me.datosInformacionApp.listaArqueologias[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaArqueologias[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaArqueologias[i].imagen = null;
                            me.datosInformacionApp.listaArqueologias[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaArqueologias[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaArqueologias[i].nombreImagen = '';
                        me.datosInformacionApp.listaArqueologias[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaFloras) {
                if (me.datosInformacionApp.listaFloras[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaFloras[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaFloras[i].imagen = null;
                            me.datosInformacionApp.listaFloras[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaFloras[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaFloras[i].nombreImagen = '';
                        me.datosInformacionApp.listaFloras[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaFaunas) {
                if (me.datosInformacionApp.listaFaunas[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaFaunas[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaFaunas[i].imagen = null;
                            me.datosInformacionApp.listaFaunas[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaFaunas[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaFaunas[i].nombreImagen = '';
                        me.datosInformacionApp.listaFaunas[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            dbInformacionApp.add({
                    idCliente: me.datosInformacionApp.idCliente,
                    idProyecto: me.datosInformacionApp.idProyecto,
                    tramo: me.datosInformacionApp.tramo,
                    coordenadas: me.datosInformacionApp.coordenadas,
                    lugar: me.datosInformacionApp.lugar,
                    titulo: me.datosInformacionApp.titulo,
                    idProyecto: me.datosInformacionApp.idProyecto,
                    listaComunidades: me.datosInformacionApp.listaComunidades,
                    listaArqueologias: me.datosInformacionApp.listaArqueologias,
                    listaFloras: me.datosInformacionApp.listaFloras,
                    listaFaunas: me.datosInformacionApp.listaFaunas,
                    listaHistorias: me.datosInformacionApp.listaHistorias,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.disabled = false;
                    me.$router.push({
                        name: 'Configuración información app'
                    })
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        async actualizarInformacionApp() {
            let me = this;
            me.disabled = true;

            for (const i in me.datosInformacionApp.listaComunidades) {
                if (me.datosInformacionApp.listaComunidades[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaComunidades[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaComunidades[i].imagen = null;
                            me.datosInformacionApp.listaComunidades[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaComunidades[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaComunidades[i].nombreImagen = '';
                        me.datosInformacionApp.listaComunidades[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaArqueologias) {
                if (me.datosInformacionApp.listaArqueologias[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaArqueologias[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaArqueologias[i].imagen = null;
                            me.datosInformacionApp.listaArqueologias[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaArqueologias[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaArqueologias[i].nombreImagen = '';
                        me.datosInformacionApp.listaArqueologias[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaFloras) {
                if (me.datosInformacionApp.listaFloras[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaFloras[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaFloras[i].imagen = null;
                            me.datosInformacionApp.listaFloras[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaFloras[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaFloras[i].nombreImagen = '';
                        me.datosInformacionApp.listaFloras[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }
            for (const i in me.datosInformacionApp.listaFaunas) {
                if (me.datosInformacionApp.listaFaunas[i].imagen != null) {
                    let miliseg = new Date().getTime();
                    try {
                        const file = me.datosInformacionApp.listaFaunas[i].imagen;
                        //console.log(file)
                        if (file) {
                            const response = await firebase
                                .storage()
                                .ref(`admin/informacion-app/${file.name}-` + miliseg)
                                .put(file);
                            const url = await response.ref.getDownloadURL();
                            me.datosInformacionApp.listaFaunas[i].imagen = null;
                            me.datosInformacionApp.listaFaunas[i].nombreImagen = file.name;
                            me.datosInformacionApp.listaFaunas[i].urlImagen = url;
                        }
                    } catch (error) {
                        me.file = null;
                        me.datosInformacionApp.listaFaunas[i].nombreImagen = '';
                        me.datosInformacionApp.listaFaunas[i].urlImagen = '';
                        me.swat('warning', 'No se pudo subir el documento ')
                    }
                }
            }

            dbInformacionApp.doc(me.datosInformacionApp.idInformacionApp).update({
                    idProyecto: me.datosInformacionApp.idProyecto,
                    tramo: me.datosInformacionApp.tramo,
                    coordenadas: me.datosInformacionApp.coordenadas,
                    lugar: me.datosInformacionApp.lugar,
                    titulo: me.datosInformacionApp.titulo,
                    idProyecto: me.datosInformacionApp.idProyecto,
                    listaComunidades: me.datosInformacionApp.listaComunidades,
                    listaArqueologias: me.datosInformacionApp.listaArqueologias,
                    listaFloras: me.datosInformacionApp.listaFloras,
                    listaFaunas: me.datosInformacionApp.listaFaunas,
                    listaHistorias: me.datosInformacionApp.listaHistorias,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.disabled = false;
                    me.$router.push({
                        name: 'Configuración información app'
                    })
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedActualizarForm.refs.proyecto.validate();
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalAgregarPax() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosPax.nombres = '';
            this.datosPax.grupo = '';
            this.datosPax.genero = null;
            this.datosPax.idTipoDocumento = '3';
            this.datosPax.numeroDocumento = '';
            this.datosPax.fechaNacimiento = moment().format('YYYY-MM-DD');
            this.datosPax.edad = '';
            this.datosPax.nacionalidad = '';
            this.datosPax.ocupacion = '';
            this.datosPax.condicion = null;
        },
        resetModalActualizarInformacionApp() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalAgregarPax: function (val) {
            if (val == false) {
                this.resetModalAgregarPax();
            }
        },
        modalActualizarInformacionApp: function (val) {
            if (val == false) {
                this.resetModalActualizarInformacionApp();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInformacionApp.idCliente = user.idCliente;
            this.listarProyectos();
        }
    }

}
</script>
